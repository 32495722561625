import { Tag, Col, Icon, Cell, CellGroup, Toast, Tabbar, TabbarItem, NavBar } from "vant";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const active = ref(1);
    let router = useRouter();
    const phone = ref(router.currentRoute.value.query.phone);
    return {
      active,
      phone
    };
  },

  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [NavBar.name]: NavBar
  },

  data() {
    return {
      pageTitle: "用餐规则",
      active: 1
    };
  },

  methods: {
    onClickLeft() {
      this.$router.push({
        path: "./book",
        query: {
          phone: this.phone
        }
      });
    }

  }
};